<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn
        v-show="networkOnline"
        icon
        @click.stop="clickUpdateStock"
      >
        <v-icon>{{ $vuetify.icons.values.refresh }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { ALMACENES_PARTE } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    networkOnline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.descripcion
      item.subtitle = `Tipo de almacén: ${item.talmacen_desc}`
      if (item.stock.count === 0) {
        item.subtitle = item.subtitle + ` - Sin stock descargado`
        item.color = ALMACENES_PARTE.colores.sinStockDescargado
      }
      return item
    }
  },
  methods: {
    clickUpdateStock () {
      this.$emit('click-update-stock', { item: this.item, index: this.index })
    },
  },
}
</script>
